* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Montserrat', sans-serif;
}

// Colors

$white: rgb(232, 232, 232);
$dark: rgb(11, 7, 7);
$card: rgba(49, 49, 49, 0.2);
$link: rgb(0, 0, 0);
$navbg: rgb(214, 212, 212);
$iconbackground: rgb(237, 237, 237);
$imgbg: rgba(50, 50, 50, 0.2);
$sectionbg: rgb(148, 153, 158);
$heading: rgb(138, 187, 227);
$p: white;


$backgrounddark: rgb(52, 52, 52);
$carddark: rgb(75, 75, 75);
$linkdark: rgb(228, 228, 228);



.theme.dark{

    .navbar {
        background-color: rgb(28, 28, 28);
        
        .resumelink {
            color: $linkdark;
        }

        li {
            color: $linkdark;
        }

        .mode {
            background-image: url(./img/moon.png);
        }

        .bar {
            background-color: white;
        }

        ul {
            background-color: rgb(28, 28, 28);
        }
    }
    

    .title {
        background-image: url("./img/darkTitle.jpg");
    }
    .sections {
        background-color: $backgrounddark;
    }

    .aboutblurb {
        background-color: $carddark;
    }

    .project {
        background-color: $carddark;
    }

    .contactform {
        background-color: $carddark;
    }

}

.app {
    max-width: fit-content;
}

html {
    scroll-snap-type: y mandatory;
}

section {
    scroll-snap-align: start;
}


.headwrapper{
    display: flex;
    width: 100vw;
    justify-content: center;

    .heading{
        font-size: 24pt;
        text-align: center;
        color: $heading;
        border-radius: 10px;
        background-color: $card;
        padding: 10px;
        width:fit-content;
    }

}
#subheading {
    font-size: 15pt;
    color: $heading;
}

#name {
    font-weight: bold;
}

.navbar {
    width: 100vw;
    display: flex;
    position: fixed;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: $navbg;
    z-index: 999;

    .icons {
        display: flex;
        gap: 10px;

        .icon {
            padding: 0 2px;
            height: 30px;
            border-radius: 3px;
            transition: 0.3s;
            cursor: pointer;

            &:hover {
                background-color: $iconbackground;
            }
        }
        img {
            height: 30px;
            width: 30px;
        }
    }

    .navlinks {
        display: flex;
        align-items: center;

        .mode {
            margin-right: 1em;
            height: 20px;
            width: 20px;
            background-image: url(./img/sun.png);
            background-size: contain;
        }


    }

    ul {
        gap: 10px;
        display: flex;
        justify-content: space-between;
        list-style: none;

        li {
            border-bottom: 1px solid transparent;
            transition: 0.5s;
            color: $link;
            cursor: pointer;
            &:hover {
                border-bottom: 1px solid $card;
            }
        }
    }

    .resumelink {
        text-decoration: none;
        color: $link;
        border-bottom: 1px solid transparent;
        transition: 0.5s;
        &:hover {
            border-bottom: 1px solid $card;
        }
    }
}

// all sections
.sections {
    padding-top: 8%;
    background-color: $sectionbg;
}

// title 

.title {
    background-image: url("./img/lightTitle.jpg");
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    

    .titleblurb {
        text-align: center;
        background-color: $card;
        color: $white;
        padding: 10px;
        margin-bottom: 8%;
        border-radius: 10px;

        h1 {
            font-size: 36pt;
            padding-bottom: 10px;

        }

        p {
            font-size: 15pt;
            line-height: 2em;
        }
    }
}

//about

.about {

    .aboutblurb {
        margin: 0 auto;
        margin-top: 5%;
        padding: 30px 20px;
        background-color: $card;
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: fit-content;
        border-radius: 15px;
    }

    .aboutp {
        width: 450px;
        margin-right: 2em;
        color: $p;
    }

    img {
        height: 300px;
        width: 300px;
        border-radius: 50%;
        margin-right: 2em;
        margin-left: 2em;
    }
}

// Projects

.projects {
    overflow-x: hidden;

    .project {
        background-color: $card;
        border-radius: 15px;
        display: flex;
        align-items: center;
        width: 60%;
        height: fit-content;
        margin: 0 auto;
        margin-top: 20px;
        padding: 20px;

        .pimg {
            flex: 3;
            border-radius: 10px;
            padding: 10px;
            transition: 0.3s;
            &:hover {
                background-color: $imgbg;
            }

            img {
                width: 100%;
            }
        }

        .pinfo {
            flex: 5;
            padding-left: 20px;

            p {
                margin-bottom: 1em;
                color: $p;
            }

            a {
                margin-bottom: 1em;
            }
        }

        .demo {
            text-decoration: none;
            color: white;
            background-color: rgb(109, 152, 188);
            padding: 5px;
            border-radius: 10px;
            transition: 0.3s;
            &:hover {
                background-color: rgb(139, 164, 147);
            }
        }
    }

    #tech {
        width: 24px;
        height: 24px;
        margin-left: 5px;
    }

    #pheading {
        margin-bottom: 2em;
    }
}

//contact form

.contactform {

    border-radius: 5px;
    width: 35%;
    background-color: $card;
    padding: 30px;
    margin: 0 auto;
    text-align: center;
    margin-top: 3%;

    input {
        padding: 10px;
        margin: 1em 0;
        width: 100%;
        height: 30px;
        &::placeholder {
            color: white;
        }
    }

    textarea {
        padding: 10px;
        margin-top: 1em;
        width: 100%;
        height: 150px;
        background-color: transparent;
        border: 1px solid white;
        font-size: 10pt;

        &::placeholder {
            font-size: 10pt;
            color: white;
        }
    }

    .submit {
        input {
            border: none;
            border-radius: 15px;
            line-height: 0px;
            transition: 0.3s;
            cursor: pointer;

            &:hover {
                background-color: rgb(148, 192, 148);
                color: white;
            }
        }
    }

    .inputinfo {
        background: none;
        border-top: none;
        border-left: none;
        border-right: none;
    }

    .wavey {
        color: $heading;

        .contactme {
            display: inline-flex;
            padding-right: 0.5em;
        }
    }
}

//Resume 

.resume {
    object {
        padding: 0;
        height: 100vh;
        width: 100vw;
    }
}

//Thankyou

.thankyou {
    text-align: center;

    .thankyoumessage {
        background-color: $white;
        display: flex;
        height: 100vh;
        flex-direction: column;
        vertical-align: center;

        h1{
            margin-top: 7.5em;
            margin-bottom: 1em;
        }

        .homebutton {
            padding: 10px;
            transition: 0.3s;
            border: 1px solid $sectionbg;
            background: $sectionbg;
            border-radius: 15px;

            &:hover {
                border: 1px solid rgb(127, 173, 127);
                background-color: rgb(127, 173, 127);
                color: white;
            }
        }
    }
}


//Mobile Responsiveness < 500px

@media only screen and (max-width: 500px) {

    .about {

        .aboutblurb {
            display: block;
            width: 90%;
        }
    
        .aboutp {
            margin-top: 1em;
            text-align: center;
            width: 100%;
            line-height: 20pt;
        }

        .aboutimg {
            text-align: center;
            img {
                height: 200px;
                width: 200px;
            }
        }
    }

    .projects {
        .project {
            display: block;
            width: 90%;
            height: fit-content;
    
            .pimg {
                text-align: center;
                img {
                    width: 90%;
                }
            }
        }
    }

    .contactform {
        margin-top: 18%;
        width: 90%;
    }

.navbar {
    width: 100vw;
    display: flex;
    position: fixed;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: $white;

    .navlinks {
        .mode {
            margin-right: 3em;
        }
    }

    .icons {
        display: flex;
        margin-right: 1em;
        img {
            height: 30px;
            width: 30px;
        }
    }

    ul {
        position: fixed;
        left: 100%;
        top:6%;
        width: 100%;
        text-align: center;
        flex-direction: column;
        background-color: $white;
        li {
            font-size: 12pt;
        }
    }

    .on {
        left: 0%;
        padding: 10px;

        li, a {
            padding: 10px;
        }
    }

    .resumelink {
        text-decoration: none;
        color: $link;
        font-size: 12pt;
    }

    /* hamburger menu */

    .bar {
        display: block;
        width: 25px;
        height: 3px;
        margin: 5px auto;
        background-color: rgb(0, 0, 0);
        transition: all 0.3s ease-in-out;
    }
    
    .hamburger {
        position: fixed;
        cursor: pointer;
        margin-left: 3em;
        top: 1.5%;
        right: 5%;
        visibility: visible;
        z-index: 999;
    }
    
    .hamburger.active .bar:nth-child(2) {
        opacity: 0;
    }
    
    .hamburger.active .bar:nth-child(1) {
        transform: translateY(8px) rotate(45deg)
    }
    
    .hamburger.active .bar:nth-child(3) {
        transform: translateY(-8px) rotate(-45deg);
    }
}
}
